
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    detailTestResult: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const router = useRouter();

    const modal = ref({
      isShow: false,
      image: null,
      name: "",
      score: 0,
      description: "",
      file: "",
    });

    const downloadResultFile = (file: string) => {
      window.open(file, "_blank");
    };

    const showResultDetail = (result: any) => {
      modal.value = {
        isShow: true,
        image: result.image,
        name: result.name,
        score: result.score,
        description: result.description,
        file: result.pdf_file,
      };
    };

    const closeResultDetail = () => {
      modal.value = {
        isShow: false,
        image: null,
        name: "",
        score: 0,
        description: "",
        file: "",
      };
    };

    return {
      router,
      modal,
      downloadResultFile,
      showResultDetail,
      closeResultDetail,
    };
  },
});
