
import { AxiosError } from "axios";
import { defineComponent, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";

// services
import testService from "@/services/test.service";

import GcHeader from "@/components/molecules/GcHeader/index.vue";

import { IProfile, IDetailTest, IQuestion } from "./interface";

export default defineComponent({
  components: { GcHeader },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const testId = Number(route.params.id);
    const isLoading = ref<boolean>(true);
    const toast = useToast();

    // get profile
    const initProfile: IProfile = {
      name: "",
      place_of_birth: 0,
      institution_id: 0,
      last_education_from_year: "",
      last_education_to_year: "",
      profession: "",
      industry_id: 0,
      work_experience_year: 0,
      marital_status: "",
      phone: "",
      date_of_birth: "",
      province_id: 0,
      gender: "",
      last_education: "",
    };
    const profileData = ref<IProfile>(initProfile);

    const initDetailTest: IDetailTest = {
      description: "",
      image: "",
      instructions: "",
      name: "",
      total_example: 0,
      total_question: 14,
      type: "",
    };
    const detailTest = ref<IDetailTest>(initDetailTest);

    const testQuestion = ref<IQuestion[]>([]);
    const currentQuestionIndex = ref<number>(0);
    const isFilled = ref<boolean>(false);
    const answeredQuestion = ref<number | null>(null);
    const answer = ref<number[]>([]);

    const getQuestion = async () => {
      try {
        isLoading.value = true;
        const { data } = await testService.getTestListQuestionParticipant(
          testId
        );

        if (data) {
          testQuestion.value = [...testQuestion.value, ...data.example];
        }
      } catch (e) {
        let errMsg = (e as AxiosError).response?.data?.error || "Ada kesalahan";
        toast.error(errMsg);
      } finally {
        isLoading.value = false;
      }
    };

    const filteredTestQuestion = computed(() => {
      let result = null;

      if (testQuestion.value.length > 0) {
        result = testQuestion.value.filter(
          (data: IQuestion, index: number) =>
            index === currentQuestionIndex.value
        )[0];
      }

      return result;
    });

    const setAnswer = (answer: { id: number }) => {
      isFilled.value = false;
      answeredQuestion.value = answer.id;
    };

    const nextQuestion = (question: { id: number }) => {
      if (!answeredQuestion.value) return;

      answer.value[question.id] = answeredQuestion.value;

      currentQuestionIndex.value += 1;
      isFilled.value = true;
    };

    const startTest = () => {
      if (detailTest.value.type === "multifacet") {
        return router.push(`/test/live/multifacet/${testId}?index=1`);
      }

      return router.push(`/test/live/${testId}`);
    };

    const logout = () => {
      localStorage.clear();
      router.push("/login");
    };

    onMounted(async () => {
      await getQuestion();

      profileData.value = JSON.parse(localStorage.getItem("profileData") || "");
      detailTest.value = JSON.parse(localStorage.getItem("detailTest") || "");
    });

    return {
      isLoading,
      profileData,
      testQuestion,
      filteredTestQuestion,
      currentQuestionIndex,
      isFilled,
      setAnswer,
      nextQuestion,
      startTest,
      logout,
      answeredQuestion,
      router,
    };
  },
});
