
import { AxiosError } from "axios";
import { defineComponent, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";

// services
import testService from "@/services/test.service";

import GcHeader from "@/components/molecules/GcHeader/index.vue";

import {
  IProfile,
  IDetailTest,
  IQuestion,
  IAnswers,
  IAnswerUser,
} from "./interface";

export default defineComponent({
  components: { GcHeader },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const testId = Number(route.params.id);
    const isLoading = ref<boolean>(true);
    const toast = useToast();

    // get profile
    const initProfile: IProfile = {
      name: "",
      place_of_birth: 0,
      institution_id: 0,
      last_education_from_year: "",
      last_education_to_year: "",
      profession: "",
      industry_id: 0,
      work_experience_year: 0,
      marital_status: "",
      phone: "",
      date_of_birth: "",
      province_id: 0,
      gender: "",
      last_education: "",
    };
    const profileData = ref<IProfile>(initProfile);

    const testQuestion = ref<IQuestion[]>([]);

    const initDetailTest: IDetailTest = {
      description: "",
      image: "",
      instructions: "",
      name: "",
      total_example: 0,
      total_question: 0,
      type: "",
    };
    const testDetail = ref<IDetailTest>(initDetailTest);
    const answerUser = ref<IAnswerUser>({});
    const isSubmit = ref<boolean>(false);
    const showModal = ref<boolean>(false);

    const getQuestion = async () => {
      try {
        isLoading.value = true;
        const { data } = await testService.getTestListQuestionParticipant(
          testId
        );

        if (data) {
          testQuestion.value = [...testQuestion.value, ...data.test];
        }
      } catch (e) {
        let errMsg = (e as AxiosError).response?.data?.error || "Ada kesalahan";
        toast.error(errMsg);
      } finally {
        isLoading.value = false;
      }
    };

    const isDisabled = computed(() => {
      return (
        Object.keys(answerUser.value).length !== testQuestion.value.length ||
        isSubmit.value
      );
    });

    const isActive = (params: { question: IQuestion; answer: IAnswers }) => {
      const { question, answer } = params;

      if (answerUser.value[question.id] === answer.id) return true;

      return false;
    };

    const setAnswer = (params: {
      question: IQuestion;
      answer: IAnswers;
      questionIndex: number;
    }) => {
      const { question, answer, questionIndex } = params;
      answerUser.value = {
        ...answerUser.value,
        [`${question.id}`]: answer.id,
      };

      const nextIndex = questionIndex + 1;
      if (nextIndex < testQuestion.value.length) {
        const id = `question-box-${nextIndex}`;
        const yOffset = -10;
        const element = document.getElementById(id);
        let y = 0;

        if (element) {
          y =
            element.getBoundingClientRect().top -
            element.getBoundingClientRect().height +
            window.pageYOffset +
            yOffset;
        }

        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };

    const startTest = async () => {
      isSubmit.value = true;

      try {
        const payload = buildPayload(answerUser.value);
        const { data } = await testService.submitQuestionParticipant(payload);

        if (data.status === "inprogress") router.push(`/test/detail/${testId}`);
        else showModal.value = true;
      } catch (e) {
        alert(e);
      }

      isSubmit.value = false;
    };

    const buildPayload = (answer: IAnswerUser) => {
      return {
        bundle_id: testId,
        user_answer: {
          ...answer,
        },
      };
    };

    const closeModal = () => {
      showModal.value = false;
      router.push("/home");
    };

    const logout = () => {
      localStorage.clear();
      router.push("/login");
    };

    onMounted(async () => {
      await getQuestion();

      profileData.value = JSON.parse(localStorage.getItem("profileData") || "");
      testDetail.value = JSON.parse(localStorage.getItem("detailTest") || "");
    });

    return {
      isLoading,
      router,
      profileData,
      testQuestion,
      testDetail,
      answerUser,
      isSubmit,
      showModal,
      isActive,
      isDisabled,
      setAnswer,
      startTest,
      closeModal,
      logout,
    };
  },
});
