
import { AxiosError } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";

// services
import testService from "@/services/test.service";

import GcHeader from "@/components/molecules/GcHeader/index.vue";

import { IProfile, IDetailTest } from "./interface";

export default defineComponent({
  components: {
    GcHeader,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const testId = Number(route.params.id);
    const isLoading = ref<boolean>(true);
    const toast = useToast();

    // get profile
    const initProfile: IProfile = {
      name: "",
      place_of_birth: 0,
      institution_id: 0,
      last_education_from_year: "",
      last_education_to_year: "",
      profession: "",
      industry_id: 0,
      work_experience_year: 0,
      marital_status: "",
      phone: "",
      date_of_birth: "",
      province_id: 0,
      gender: "",
      last_education: "",
    };
    const profileData = ref<IProfile>(initProfile);

    const initDetailTest: IDetailTest = {
      description: "",
      image: "",
      instructions: "",
      name: "",
      total_example: 0,
      total_question: 0,
      type: "",
    };
    const detailTest = ref<IDetailTest>(initDetailTest);

    const getDetailTest = async () => {
      try {
        isLoading.value = true;
        const { data } = await testService.getTestDetailParticipant(testId);

        if (data) {
          detailTest.value = {
            ...detailTest.value,
            ...data,
          };
          localStorage.setItem("detailTest", JSON.stringify(detailTest.value));
        }
      } catch (e) {
        let errMsg = (e as AxiosError).response?.data?.error || "Ada kesalahan";
        toast.error(errMsg);
      } finally {
        isLoading.value = false;
      }
    };

    const startTest = () => {
      if (detailTest.value.total_example > 0) {
        return router.push(`/test/practice/${testId}`);
      }

      if (detailTest.value.type === "multifacet") {
        return router.push(`/test/live/multifacet/${testId}?index=1`);
      }

      return router.push(`/test/live/${testId}`);
    };

    const logout = () => {
      localStorage.clear();
      router.push("/login");
    };

    onMounted(async () => {
      await getDetailTest();

      profileData.value = JSON.parse(localStorage.getItem("profileData") || "");
    });

    return {
      isLoading,
      profileData,
      detailTest,
      startTest,
      logout,
      router,
    };
  },
});
