
import { AxiosError } from "axios";
import { defineComponent, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";

// services
import testService from "@/services/test.service";

import GcHeader from "@/components/molecules/GcHeader/index.vue";

import {
  IProfile,
  IDetailTest,
  IQuestion,
  IQuestionIterable,
  IAnswers,
  IAnswerUser,
  ISections,
} from "./interface";

export default defineComponent({
  components: { GcHeader },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const testId = Number(route.params.id);
    const currentIndex = ref<number>(Number(route.query.index));
    const isLoading = ref<boolean>(true);
    const toast = useToast();

    // get profile
    const initProfile: IProfile = {
      name: "",
      place_of_birth: 0,
      institution_id: 0,
      last_education_from_year: "",
      last_education_to_year: "",
      profession: "",
      industry_id: 0,
      work_experience_year: 0,
      marital_status: "",
      phone: "",
      date_of_birth: "",
      province_id: 0,
      gender: "",
      last_education: "",
    };
    const profileData = ref<IProfile>(initProfile);

    const testQuestion = ref<IQuestionIterable[]>([]);

    const initDetailTest: IDetailTest = {
      description: "",
      image: "",
      instructions: "",
      name: "",
      total_example: 0,
      total_question: 0,
      type: "",
    };
    const testDetail = ref<IDetailTest>(initDetailTest);
    const answerUser = ref<IAnswerUser>({});
    const isSubmit = ref<boolean>(false);
    const showModal = ref<boolean>(false);
    const currentSection = ref<number>(1);
    const sections = ref<ISections>({});
    const lastSection = ref<number>(0);

    const getQuestion = async () => {
      try {
        isLoading.value = true;

        const { data } = await testService.getTestListQuestionParticipant(
          testId
        );

        if (data && data.sections) {
          let index = 1;
          const keysSection = Object.keys(data.sections);
          lastSection.value = keysSection.length;

          for (const i of keysSection) {
            if (data.sections[i].status === "inprogress") {
              index = Number(i);
              break;
            }
          }

          if (currentIndex.value !== index) {
            currentIndex.value = index;
            const path = `/test/live/multifacet/${testId}?index=${index}`;
            router.push(path);
          }

          testQuestion.value = [
            ...(data.sections[index].questions as IQuestionIterable),
          ];
          sections.value = data.sections;
        }
      } catch (e) {
        let errMsg = (e as AxiosError).response?.data?.error || "Ada kesalahan";
        toast.error(errMsg);
      } finally {
        isLoading.value = false;
      }
    };

    const filledSection = computed(() => {
      if (!sections.value && totalSection.value === 0) return 0;

      let curr = 1;
      for (let i = 1; i <= totalSection.value; i++) {
        if (sections.value[i].status === "inprogress") {
          curr = i;
          break;
        }
      }

      return curr - 1;
    });

    const totalSection = computed(() => {
      return Object.keys(sections.value).length;
    });

    const isDisabled = computed(() => {
      return (
        Object.keys(answerUser.value).length !== testQuestion.value.length ||
        isSubmit.value
      );
    });

    const isActive = (params: { question: IQuestion; answer: IAnswers }) => {
      const { question, answer } = params;

      if (answerUser.value[question.id] === answer.id) return true;

      return false;
    };

    const setAnswer = (params: {
      question: IQuestion;
      answer: IAnswers;
      questionIndex: number;
    }) => {
      const { question, answer, questionIndex } = params;
      answerUser.value = {
        ...answerUser.value,
        [`${question.id}`]: answer.id,
      };

      const nextIndex = questionIndex + 1;
      if (nextIndex < testQuestion.value.length) {
        const id = `question-box-${nextIndex}`;
        const yOffset = -10;
        const element = document.getElementById(id);
        let y = 0;

        if (element) {
          y =
            element.getBoundingClientRect().top -
            element.getBoundingClientRect().height +
            window.pageYOffset +
            yOffset;
        }

        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };

    const resetData = async () => {
      answerUser.value = {};
      testQuestion.value = [];
      await getQuestion();
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const startTest = async () => {
      isSubmit.value = true;

      try {
        const payload = buildPayload(answerUser.value);
        await testService.submitQuestionParticipant(payload);

        if (currentIndex.value < lastSection.value) {
          currentIndex.value += 1;
          const path = `/test/live/multifacet/${testId}?index=${currentIndex.value}`;
          router.push(path);
          resetData();
        } else {
          showModal.value = true;
        }
      } catch (e) {
        let errMsg = (e as AxiosError).response?.data?.error || "Ada kesalahan";
        toast.error(errMsg);
      }

      isSubmit.value = false;
    };

    const buildPayload = (answer: IAnswerUser) => {
      return {
        bundle_id: testId,
        user_answer: {
          ...answer,
        },
        section: currentIndex.value,
      };
    };

    const closeModal = () => {
      showModal.value = false;
      router.push("/home");
    };

    const logout = () => {
      localStorage.clear();
      router.push("/login");
    };

    onMounted(async () => {
      await getQuestion();

      profileData.value = JSON.parse(localStorage.getItem("profileData") || "");
      testDetail.value = JSON.parse(localStorage.getItem("detailTest") || "");
    });

    return {
      isLoading,
      router,
      currentSection,
      filledSection,
      totalSection,
      profileData,
      testQuestion,
      testDetail,
      answerUser,
      isSubmit,
      showModal,
      isActive,
      isDisabled,
      setAnswer,
      startTest,
      closeModal,
      logout,
    };
  },
});
