
import { AxiosError } from "axios";

import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useToast } from "vue-toastification";

// services
import testService from "@/services/test.service";

export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    detailTestResult: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const bundleId = Number(route.params.bundleId);
    const resultId = route.params.resultId;
    const isLoadingDownload = ref<boolean>(false);

    const downloadResult = async () => {
      try {
        isLoadingDownload.value = true;
        const { data } = await testService.downloadTestResult({
          bundleId,
          resultId,
        });

        window.open(data, "_blank");
      } catch (e) {
        let errMsg = (e as AxiosError).response?.data?.error || "Ada kesalahan";
        toast.error(errMsg);
      } finally {
        isLoadingDownload.value = false;
      }
    };

    return {
      router,
      downloadResult,
    };
  },
});
