
import { AxiosError } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";

// services
import testService from "@/services/test.service";

import GcHeader from "@/components/molecules/GcHeader/index.vue";
import TemplateReport from "@/components/templates/report/index.vue";
import TemplateReportMultifacet from "@/components/templates/report-multifacet/index.vue";

import { IProfile, IDetailTestResult } from "./interface";

export default defineComponent({
  components: {
    GcHeader,
    TemplateReport,
    TemplateReportMultifacet,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const bundleId = Number(route.params.bundleId);
    const resultId = route.params.resultId;
    const isLoading = ref<boolean>(false);
    const toast = useToast();

    // get profile
    const initProfile: IProfile = {
      name: "",
      place_of_birth: 0,
      institution_id: 0,
      last_education_from_year: "",
      last_education_to_year: "",
      profession: "",
      industry_id: 0,
      work_experience_year: 0,
      marital_status: "",
      phone: "",
      date_of_birth: "",
      province_id: 0,
      gender: "",
      last_education: "",
    };
    const profileData = ref<IProfile>(initProfile);

    const initDetailTestResult: IDetailTestResult = {
      created_at: "",
      description: "",
      highlight: "",
      id: "",
      image: "",
      name: "",
      payment: "",
      test_results: [],
      user_bundle_result_id: "",
      user_results: "",
      bundle: {
        name: "",
      },
    };
    const detailTestResult = ref<IDetailTestResult>(initDetailTestResult);

    const getDetailTest = async () => {
      try {
        isLoading.value = true;
        const { data } = await testService.getTestResult({
          bundleId: bundleId,
          resultId,
        });

        detailTestResult.value = {
          ...detailTestResult.value,
          ...data,
        };
      } catch (e) {
        let errMsg = (e as AxiosError).response?.data?.error || "Ada kesalahan";
        toast.error(errMsg);
      } finally {
        isLoading.value = false;
      }
    };

    const logout = () => {
      localStorage.clear();
      router.push("/login");
    };

    onMounted(async () => {
      await getDetailTest();

      profileData.value = JSON.parse(localStorage.getItem("profileData") || "");
    });

    return {
      isLoading,
      profileData,
      detailTestResult,
      logout,
      router,
    };
  },
});
